.collection-settings-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

@media (max-width: 840px) {
    .collection-settings-wrapper {
        flex-direction: column;
    }
}

.collection-settings-box {
    padding: 20px 15px;
    border: 1px solid #aaa;
    border-top: none;
    border-bottom: none;
    width: 100%;
    box-sizing: border-box;
    max-width: 900px;
}

.collection-search-inputs {
    margin-top: 50px;
}

.search-input-row {
    margin: 15px 0;
    font-size: 16px;
}

.search-input-row .head {
    font-weight: bold;
    margin-bottom: 10px;
}

.search-input-row input {
    width: 330px;
    height: 24px;
    padding-left: 10px;
}

.collection-search-btn-wrapper {
    margin-top: 30px;
}

.collection-search-btn-wrapper button {
    background-color: #2288ee;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    width: 80px;
    height: 40px;
}

.collection-search-btn-wrapper button:hover {
    cursor: pointer;
}

.collection-search-btn-wrapper button:disabled {
    opacity: 0.5;
}

.collection-info {
    margin-top: 60px;
}

.collection-info li {
    font-weight: bold;
    overflow: hidden;
}

.collection-info .no-info {
    margin: 30px 0;
    padding: 5px 15px;
    border-radius: 6px;
    background-color: pink;
}

.collection-info .info-exists {
    margin: 30px 0;
    padding: 5px 15px;
    border-radius: 6px;
    background-color: #a7d5a7;
}

.collection-nft-list {
}

.collection-nft-list .nft-list-item {
    margin: 5px;
    width: 100px;
    display: inline-flex;
    flex-direction: column;
}

.collection-nft-list .nft-list-item img {
    width: 100px;
    height: auto;
}

.collection-info-edit {
    margin-top: 30px;
}

.img-showcase {
    background-color: #eee;
    margin-bottom: 30px;
}

.img-showcase .head {
    padding-top: 15px;
    margin-left: 15px;
    font-weight: bold;
    padding-bottom: 60px;
}

.img-showcase .banner-img-wrapper img {
    width: 100%;
    height: auto;
}

.img-showcase .profile-img-wrapper {
    margin-top: -40px;
    margin-left: 10%;
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-showcase .profile-img-wrapper img {
    width: auto;
    height: 80px;
}

.collection-info-edit .property-row {
    margin: 15px 0;
    display: flex;
    flex-direction: row;
}

@media (max-width: 500px) {
    .collection-info-edit .property-row {
        flex-direction: column;
    }
    
}

.collection-info-edit .property-row .input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.collection-info-edit .property-row .head {
    font-weight: bold;
    font-size: 16px;
    min-width: 120px;
    margin: 10px 0;
    margin-right: 30px;
}

.collection-info-edit .property-row input[type=text] {
    width: 300px;
    height: 20px;
}

.collection-info-edit .property-row textarea {
    min-width: 300px;
    min-height: 60px;
}

.marketplace-input-row {
    margin: 15px;
    display: flex;
    flex-direction: row;
}

.marketplace-input-row .marketplace-input-wrapper {
    margin-right: 30px;
}

.sns-link-settings {
}

.sns-link-settings .input-list .guide {
    margin: 10px;
    font-size: 14px;
    color: #666;
}

.sns-link-settings .input-list .sns-link-input-row {
    margin: 15px 0;
    display: flex;
    flex-direction: row;
    align-items: cneter;
}

.sns-link-settings .input-list .sns-link-input-row input {
    width: 200px;
}

.x-btn {
    margin-left: 20px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.x-btn:hover {
    cursor: pointer;
}

.plus-btn {
    background-color: rgb(50, 117, 250);
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding: 8px 15px;
    border-radius: 8px;
    border: none;
}

.plus-btn:hover {
    cursor: pointer;
}

.save-btn-wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.save-btn-wrapper button {
    background-color: darkgreen;
    border-radius: 12px;;
    border: none;
    color: white;
    width: 160px;
    height: 50px;
    font-size: 18px;
    font-weight: bold;
}

.save-btn-wrapper button:disabled {
    opacity: .5;
}
