.policy-settings-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 600px;
    padding: 30px 15px;
}

.policy-settings-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #deefff;
    border-radius: 12px;
    padding: 30px;
    width: 100%;
    max-width: 600px;
    min-height: 500px;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.policy-settings-box h3 {
    margin-bottom: 30px;
}

.policy-settings-box .setting-row {
    margin: 15px 0;
    min-width: 360px;
}

.policy-settings-box .setting-row .head {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 15px;
}

.policy-settings-box .setting-row input {
    padding-left: 15px;
    border-radius: 6px;
    border: 1px solid #888;
    height: 28px;
    width: 100%;
    box-sizing: border-box;
    font-size: 18px;
    padding: 18px;
}

.policy-settings-box .button-row {
    margin-top: 30px;
}

.policy-settings-box .button-row button {
    padding: 10px;
    background-color: #5188ff;
    color: white;
    border: 1px solid #eee;
    border-radius: 8px;
    min-width: 140px;
    box-shadow: none;
    font-size: 18px;
}

.policy-settings-box .button-row button:hover {
    cursor: pointer;
}

.policy-settings-box .button-row button:disabled {
    opacity: .5;
}
