.admin-list {
    padding: 15px;
    padding-bottom: 30px;
    margin-top: 50px;
    background-color: white;
    border-radius: 12px;
    min-width: 330px;
}

.admin-list .admin-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #dedede;
}

.admin-list .admin-list-item .grey {
    margin-top: 3px;
    font-size: 13px;
    color: grey;
}

.remove-admin-btn-wrapper button {
    border-radius: 6px;
    background-color: red;
    color: white;
    border: none;
    padding: 8px 15px;
}

.remove-admin-btn-wrapper button:hover {
    cursor: pointer;
}

