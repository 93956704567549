.carousel-list-page-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #eee;
}

.carousel-list-page {
    background-color: white;
    padding: 15px 60px;
    width: 100%;
    max-width: 660px;
    min-height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.carousel-list-page .action-row {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.carousel-list-page .action-row button {
    border: none;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    background-color: #2557ed;
    padding: 8px 15px;
}

.carousel-list-page .carousel-list {
    margin-top: 50px;
    width: 100%;
    text-align: center;
    border: 1px solid #ddd;
    border-collapse: collapse;
}

.carousel-list-page .carousel-list tr {
    border: 1px solid #ddd;
}

.carousel-list-page .carousel-list tbody tr:hover {
    background-color: #eee !important;
}

.carousel-list-page .carousel-list .carousel-list-item {
}
