.test-features {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.test-features button {
    margin: 15px;
    padding: 5px 10px;
    font-size: 16px;
}

.test-features button:hover {
    cursor: pointer;
}
