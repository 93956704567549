.signin-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.signin-box {
    padding: 15px 30px;
    background-color: #95d9fd;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.signin-box > div {
    margin: 15px 0;
}

.signin-box input {
    border-radius: 6px;
    border: 1px solid #ababab;
    width: 280px;
    height: 28px;
    padding-left: 15px;
}

.signin-box .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.signin-box .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.signin-box .button-wrapper button {
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    box-shadow: none;
    font-weight: bold;
}
