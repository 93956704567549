.memberstores {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 10px;
}

.memberstores hr {
    width: 100%;
    max-width: 600px;
    margin: 30px 0;
}

.memberstore-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin: 20px 0;
}

.memberstore-list button:hover {
    cursor: pointer;
}

.memberstore-list .head-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.memberstore-list .head-row .title {
    flex: 1;
    font-size: 18px;
    font-weight: bold;
}

.memberstore-list .head-row .search {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 30px;
}

.memberstore-list .head-row .search input {
    margin-right: 15px;
    width: 180px;
}

.memberstore-list .head-row .fold-btn:hover {
    cursor: pointer;
}

.memberstore-list .head-row .fold-btn svg {
    width: 30px;
    height: 30px;
}

.memberstore-list .list-items-wrapper {
    width: 100%;
    border: 1px solid #ddd;
}

.memberstore-list .list-items-wrapper .list-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 5px 10px;
}

.memberstore-list .list-items-wrapper .list-item:not(:last-child) {
    border-bottom: 1px solid #ddd;
}

.memberstore-list .list-items-wrapper .list-item:hover {
    background-color: #eee;
}

.memberstore-list .list-items-wrapper .list-item .hold-section {
    display: flex;
    flex-direction: row;
}

.memberstore-list .list-items-wrapper .list-item a {
    color: black;
    font-weight: bold;
    text-decoration: none;
}

.memberstore-list .list-items-wrapper .list-item .tag {
    font-size: 11px;
    font-weight: bold;
    margin-right: 8px;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.memberstore-list .list-items-wrapper .list-item .tag.tag-update {
    background-color: rgb(96, 210, 96);
}

.memberstore-list .list-items-wrapper .list-item .tag.tag-new {
    background-color: rgb(75, 187, 239);
}

.memberstore-list .list-items-wrapper .list-item .name {
    flex: 1
}

.memberstore-list .list-items-wrapper .list-item .btn-wrapper {
    margin: 0 3px;
}

.memberstore-list .list-items-wrapper .list-item .btn-wrapper button {
    border: none;
    padding: 4px 8px;
    border-radius: 3px;
}

.memberstore-list .list-items-wrapper .list-item .hold-section {
    margin-left: 30px;
}

.memberstore-list .list-items-wrapper .list-item .btn-verify {
    color: white;
    background-color: rgb(5, 162, 110);
}


