.incomplete-collection-list {
    margin-bottom: 60px;
    padding: 20px;
    min-width: 400px;
}

.incomplete-collection-list .header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.incomplete-collection-list .header:hover {
    cursor: pointer;
}

.incomplete-collection-list .table-wrapper {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.incomplete-collection-list table {
    text-align: center;
    border: 1px solid #aaa;
    border-collapse: collapse;
}

.incomplete-collection-list table tr {
    border: 1px solid #aaa;
}

.incomplete-collection-list table th {
    padding: 3px 12px;
}

.incomplete-collection-list table tbody tr:hover {
    background-color: #eee !important;
}

.incomplete-collection-list table button {
    display: inline-block;
}
