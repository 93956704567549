.collection-bundle-settings-page-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #eee;
}

.collection-bundle-settings-page {
    background-color: white;
    padding: 15px 60px;
    width: 100%;
    max-width: 660px;
    min-height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.collection-bundle-settings-page .title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;
}

.collection-bundle-settings-page h2 {
    margin-left: 15px;
}

.collection-bundle-settings-page .delete-btn-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.collection-bundle-settings-page .setting-row {
    display: flex;
    flex-direction: row;
    margin: 15px 0;
}

.collection-bundle-settings-page .setting-row .head {
    min-width: 120px;
    margin-right: 15px;
    font-weight: bold;
}

.collection-bundle-settings-page .button-row {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.collection-bundle-settings-page .button-row button {
    border: none;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    font-size: 16px;
    background-color: #2557ed;
    padding: 8px 15px;
}

.collection-bundle-settings-page .search-section {
    margin-top: 50px;
}

.collection-bundle-settings-page .search-input-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.collection-bundle-settings-page .search-input-row .input-wrapper {
    margin-right: 15px;
}

.collection-bundle-settings-page .collection-list-item {
    border: 1px solid #ddd;
    padding: 10px;
    display: inline-flex;
    margin: 10px;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
}

.collection-bundle-settings-page .collection-list-item > div {
    margin: 5px 0;
}

.collection-bundle-settings-page .collection-list-item .img-wrapper {
    height: 100px;
    width: 100%;
    background-color: #efefef;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.collection-bundle-settings-page .collection-list-item .img-wrapper img {
    width: auto;
    height: 100%;
}
