.carousel-settings-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #eee;
}

.carousel-settings {
    background-color: white;
    padding: 15px 60px;
    width: 100%;
    max-width: 660px;
    min-height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.carousel-settings .title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.carousel-settings .title-row h2 {
    margin-left: 15px;
}

.carousel-settings .img-display h4 {
    margin-bottom: 5px;
}

.carousel-settings .img-display img {
    height: 300px;
    width: auto;
}

.carousel-settings .setting-row {
    margin: 15px 0;
}

.carousel-settings .setting-row .head {
    font-weight: bold;
    margin-bottom: 5px;
}

.carousel-settings .setting-row .input-wrapper input[type=text] {
    min-width: 200px;
}

.carousel-settings .setting-row .input-wrapper textarea {
    min-width: 280px;
    min-height: 80px;
}
