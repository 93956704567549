
.error-box {
    border: 1px solid red;
    border-radius: 12px;
    padding: 15px;
    background-color: pink;
}

nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
    width: 100%;
    padding: 10px 0;
    background-color: #333;
}

.nav-title {
    margin: 0 15px;
    font-size: 15px;
    font-weight: bold;
}

.nav-menu {
    margin-left: 30px;
    display: flex;
    flex-direction: row;
}

.nav-menu-item {
    margin: 8px;
    font-size: 13px;
    font-weight: bold;
}

.nav-menu-item a {
    text-decoration: none;
    color: white;
}

@media (max-width: 700px) {
    .nav-title {
        font-size: 14px;
    }

    .nav-menu {
        margin-left: 0;
    }
    
    .nav-menu-item {
        font-size: 13px;
        margin: 8px;
    }
}
