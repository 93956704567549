.marketplaces-settings {
    padding: 10px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.marketplaces-settings > div {
    width: 100%;
    max-width: 500px;
}

.marketplaces-settings .marketplaces-settings {
    display: flex;
    flex-direction: column;
}

.marketplaces-settings .add-exchange {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
}

.marketplaces-settings .add-exchange .input-wrapper {
    margin-right: 15px;
}

.marketplaces-settings .add-exchange .input-wrapper input {
    width: 200px;
}

.marketplaces-settings .exchange-list {
    display: flex;
    flex-direction: column;
}

.marketplaces-settings .exchange-list .exchange-list-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin: 5px;
}

.marketplaces-settings .exchange-list .exchange-list-item:hover {
    background-color: #eee;
}

.marketplaces-settings .exchange-list .exchange-list-item .left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.marketplaces-settings .exchange-list .exchange-list-item .left .light {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    margin-right: 8px;
}

.marketplaces-settings .exchange-list .exchange-list-item .right {
    display: flex;
    flex-direction: row;
}

.marketplaces-settings .exchange-list .exchange-list-item .btn-wrapper {
    margin-left: 10px;
}

.marketplaces-settings .exchange-list .exchange-list-item .btn-wrapper button {
    border: none;
    border-radius: 6px;
    color: white;
    padding: 3px 6px;
}

.marketplaces-settings .exchange-list .exchange-list-item .btn-wrapper .text-black {
    color: black;
}

.marketplaces-settings .exchange-list .exchange-list-item .btn-wrapper button:hover {
    cursor: pointer;
}

